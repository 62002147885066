<template>
  <div class="box" id="boxFixed"  :class="{'is_fixed' : isFixed}">
    <div class="nav public-width width-auto flex">
        <el-image class="logo" :src="log_src"></el-image>

        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
        >
          <el-menu-item index="1" @click="toPage('Index')"> 
            首页 <a href="/" style="display:none">首页</a>
          </el-menu-item>
          <el-menu-item index="2" @click="toPage('app')" >
            启迪春雨<a href="/app" style="display:none">启迪春雨</a> 
          </el-menu-item>
          <el-menu-item index="3"  @click="toPage('piano')">
            美育教育<a href="/piano" style="display:none">美育教育</a>
          </el-menu-item>
           <el-menu-item index="4" @click="toPage('sports')">
            体育教育<a href="/sports" style="display:none">体育教育</a>
          </el-menu-item>          
          <!-- <el-menu-item index="6" @click="openshop">
            乐器商城
          </el-menu-item> -->
           <el-menu-item index="7" @click="toPage('About')">
             关于我们<a href="/About" style="display:none">关于我们</a>
          </el-menu-item>
        </el-menu>

        <div class="tal hover-pop">联系电话：13908715880</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "1",
      activeIndex2: "1",
      log_src: require("@/assets/logo.png"),
      isFixed: false,
      offsetTop: 0,
      current_url:''
    };
  },
  mounted() {
      window.addEventListener('scroll', this.initHeight);
      this.$nextTick(() => {
          //获取对象相对于版面或由 offsetTop 属性指定的父坐标的计算顶端位置 
          this.offsetTop = document.querySelector('#boxFixed').offsetTop;
      })
  },
   //回调中移除监听
  destroyed() {
      window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    // handleSelect (key, keyPath) {
    // },
    toPage(url) {   
      if (this.current_url==url  ){
        return
      }
      this.current_url=url   
      this.$router.push({ name: url });
    },
    // openshop(){
    //   let url="http://h5.tusparkchuyu.com"
    //   window.open(url)
    // },
    initHeight() {
        // 设置或获取位于对象最顶端和窗口中可见内容的最顶端之间的距离 (被卷曲的高度)
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        //如果被卷曲的高度大于吸顶元素到顶端位置 的距离
        this.isFixed = scrollTop > this.offsetTop ? true : false;
    },
  },
};
</script>

<style lang="less" scoped>
 .box{
    width: 100%;
   
  }
  .is_fixed{
    position: fixed;
    top: 0;
    z-index: 999;
    border-bottom: 1px solid #dfdfdf;	
  }
.el-menu--horizontal > .el-menu-item.is-active {
  border: 0px solid white;
}
.el-menu.el-menu--horizontal {
  border: none;
}

.el-menu--horizontal > .el-menu-item {
  margin: 0 10px;
}
.nav {
  display: flex;
  align-items: center;
  justify-content: start;
  font-weight: 600;
  padding: 10px 0;
  // logo大小
  .logo {
    width: 180px;
    margin-right:50px;
  }
  .tal {
    font-size: 14px;
    margin-left: 50px;
  color:  rgb(145, 145, 145);
  cursor: pointer;

  }
}

</style>