<template>
  <div class="introduce">

    <h1 class="index-h2">启迪智慧教育</h1>

    <el-row>
      <el-col class="col"
              :xs="24"
              :md="12">

        <h3 class="">北京启迪邦妙文体科技有限公司</h3>
        <span class="msg">TusParkChuYu</span>
        <div class="hr"></div>
        <p>
         项目授牌：
中国关心下一代工作委员会健康体育中心“启迪智慧教育”服务中心
        </p>
        <br>
        <p>
          中国关心下一代工作委员会健康体育发展中心是在国家事业单位登记管理局登记的事业法人单位，是中国关工委的直属事业单位 。
2022年中国关心下一代工作委员会健康体育发展中心批复设立“启迪智慧教育”公益项目，北京启迪邦妙文体科技有限公司为项目的承办单位。

        </p>
          <br>
        <p>
          以创新的力量，普及素质教育以平台的规模，赋能教育发展启迪智慧教育云，结合智能科技力量，运用“互联网+”先进思维，充分利用各种资源，以义务教育阶段校园为基地，以体育、美育教育课程为载体，协助校园普及素质教育。
启迪智慧教育云，利用大数据时代逻辑，充分挖掘大数据价值，助推义务教育高效管理，赋能教育行业不断发展。
        </p>
      </el-col>


      <el-col :xs="24"
              :md="12">

        <div class="  hvr-grow">
          <el-image :src="url"
                    
                    class="img-box ">
            <div slot="placeholder"
                 class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
        </div>

      </el-col>

    </el-row>

     <h1 class="index-h2">项目范围</h1>
 <el-row>
   <el-col class="col"
              :xs="24"
              :md="12">
      <el-image :src="url2"
                    
                    class="img-box2 ">
            <div slot="placeholder"
                 class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
         </el-col>

    </el-row>

 <h1 class="index-h2">应用案例</h1>
 <el-row>
   <el-col class="col"
              :xs="24"
              :md="12">
      <el-image :src=" require('@/assets/ee15.jpg')"
                    
                    class="img-box2 ">
            <div slot="placeholder"
                 class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
         </el-col>

    </el-row>


 <h1 class="index-h2">合作伙伴</h1>
 <el-row>
   <el-col class="col"
              :xs="24"
              :md="12">
      <el-image :src=" require('@/assets/ee16-1.jpg')"
                    
                    class="img-box2 ">
            <div slot="placeholder"
                 class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
         </el-col>

    </el-row>

<el-row>
   <el-col class="col"
              :xs="24"
              :md="12">
      <el-image :src=" require('@/assets/ee16-2.jpg')"
                    
                    class="img-box2 ">
            <div slot="placeholder"
                 class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
         </el-col>

    </el-row>

 <h1 class="index-h2">专家团队</h1>
 <el-row>
   <el-col class="col"
              :xs="24"
              :md="12">
      <el-image :src=" require('@/assets/ee17.jpg')"
                    
                    class="img-box2 ">
            <div slot="placeholder"
                 class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
         </el-col>

    </el-row>



  </div>
</template>


<script>
export default {
  data () {
    return {
      url: require('@/assets/ee13.jpg'),
       url2: require('@/assets/ee14.jpg')

    }
  },
  methods: {

  },
  //   components: {

  //   },
}
</script>

<style lang="less" scoped>
.introduce {
  padding-bottom: 100px;
}
.el-row {
  display: flex;
  align-items: center;
}
.col {
  text-align: left;
   
  h3 {
    margin-bottom: 5px;
  }
  .hr {
    margin-top: 20px;
    margin-bottom: 20px;
    height: 2px;
    background-color: whitesmoke;
  }
  .msg {
    font-size: 14px;
  }

  p {
    font-size: 16px;
    line-height: 35px;
  }

  .img-box{
    width: 300px;
  }
  .img-box2{
    width: 1200px;
  }
}
</style>
<style lang="less" >
</style>