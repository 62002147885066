<template>
  <div class="home">
    <Navbar />
     
    <Introduce />
    
  </div>
</template>


<script>
// import Nav from '@/components/Nav.vue'
import Navbar from '@/components/index/Navbar.vue'
import Introduce from '@/components/index/Introduce.vue'
 
export default {
  components: {
    Navbar,
    Introduce,
    
    
  }
}
</script>

<style lang="less" scoped>
.home div {
  // margin: 120px 0;1
  // ad
}
.home div:nth-child(1) {
  margin: 0 0;
}
</style>