<template>
  <div>
    <!-- 关于我们 -->

    <!-- 背景大图 -->
    <!-- <div class="img-box bj1 position-abs-100">
      <el-image fit="cover"
                style="width:100%"
                :src="require('@/assets/gywm.jpg')"></el-image>

    </div>
    <div class="img-box"></div> -->

    <div class="box box2">
     <h1 class="index-h2">公司概况</h1>
      <div class="body ">
        <el-row>
          <el-col :md="12"
                  class="text">
            <h2>北京启迪邦妙文体科技有限公司</h2>
            <p>北京启迪邦妙文体科技有限公司是启迪控股集团下属启迪科技城集团依法设立的控股公司，公司长年致力于青少年素质教育智能化平台建设工作，拥有多项智能教育领域专利、软件著作权等产品成果，是启迪控股集团内专业从事青少年素质教育服务的控股子公司。</p>
          <p>
            启迪科技城集团是启迪控股的全资子公司，拥有26年科技园区建设运营经验，坚持“科技成果转化、创业企业孵化、创新人才培养”的信念，成功构建起以孵化器、科技园，科技城为载体的全球创新服务网络，建设运营创新空间66个，面积近1200万平方米，入驻科技企业5000多家。旗下拥有子公司启迪设计（股票代码：300500）、 青岛双星 (股票代码： 000599)、启迪冰雪、启迪物联网、启迪公交、亚都、浙江机器人等公司300余家，引领全球城市科技创新发展。

          </p>
          </el-col>
          <el-col :md="12">
            <div class="img">
              <div>
                <el-image style="width:100%" :src="require('@/assets/gonstu1.jpg')"></el-image>
              </div>
              <div>
                <el-image style="width:100%" :src="require('@/assets/gonstu2.jpg')"></el-image>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="box box1"
        >
      
       <h1 class="index-h2">联系我们</h1>
      
      <div class="message">
        <el-row>
          <el-col :md="12">
            <div class="list">
              <div>官方网站：www.tusparkchuyu.com</div>
              <div>联系电话：13908715880</div>
              <div>电子邮箱：tusparkchuyu@163.com</div>          
              <div>
                地址：北京市延庆区中关村延庆园东环路2号楼1659室
              </div>
            </div>
          </el-col>
          <el-col :md="12">
            <el-image style="width: 600px; height: 300px"
                     :src="require('@/assets/map.jpg')"
                      fit="contain"></el-image>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {};
  },
  metaInfo: {
    title: '北京启迪邦妙文体科技有限公司-关于我们', 
    meta: [{                 
      name: '启迪邦妙,妙音乐教室,智能音乐教室,启迪春雨,启迪智慧教育,智能钢琴,钢琴陪练,钢琴考级,智能架子鼓,腰旗橄榄球',
      content: '北京启迪邦妙文体科技有限公司长年致力于青少年素质教育智能化平台建设工作，拥有多项智能教育领域专利、软件著作权等产品成果，是启迪控股集团内专业从事青少年素质教育服务的控股子公司。'
    }]      
  } 
};
</script>

<style lang="less" scoped>
.img-box {
  height: 300px;
  box-shadow: inset 1px 5px 10px #bfbfbf73;
  margin-bottom: 100px;
  overflow: hidden;
}
.box {
  margin-bottom: 50px;
  .title {
    width: 300px;
    height: 60px;
    margin: 0 auto;
  }
  .body {
    margin-top: 20px;
    height: 400px;
    text-align: left;
    .text {
      line-height: 2;
      div {
        margin-bottom: 20px;
      }
    }
  }
}
.box1 {
  .message {
    margin-top: 20px;
    height: 400px;

    .list {
      padding-top: 100px;
      div {
        margin-bottom: 10px;
        
        text-align: left;
      }
    }
  }
}
.box2 {
  .text {
    padding: 10px;
  }
  h2 {
  }
  p {
    margin: 20px 0 10px 0;
  }
  span {
    font-size: 14px;
  }
  .img {
    display: flex;
    justify-content: center;
    div {
      width: 100%;
      background-color: rgb(247, 247, 247);
      margin-right: 10px;
    }
  }
  
}
</style>